$spacing: 5px;

.iqitproductvariants {
    &__product-col {
        width: 14.2857%;
        max-width: 100px;
        @media (min-width: 1200px) {
            width: 14.2857%;
        }
    }
    &__product {
        position: relative;
        display: block;
        text-align: center;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transition: box-shadow 0.3s ease;
        }

        opacity: 0.7;
        &:hover,
        &--current {
            opacity: 1;
            &::before {
                content: "";
                box-shadow: inset 0 0 0 1px rgba(160, 160, 160, .25);
            }
        }

        @media (min-width: 1200px) {
            &--hidden-desktop {
                display: none;
            }
        }
        @media (max-width: 1199px) {
            &--hidden-mobile {
                display: none;
            }
        }
    }
    &__img {
        &--first {

        }
    }
    &__btn-more {
        cursor: pointer;
        margin: 0 0 0 1px;
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &--hidden {
            display: none;
        }
    }
}
